import "../../resources/less/Constellation/LandingPage.less";
import NanoPopup from "../components/Popups/NanoPopup";
import HorizontalCarrousel from "../components/HorizontalCarrousel";
import JQuery from "jquery";

export default class ConstellationLanding {
  private readonly carrouselContainer: JQuery<HTMLElement>;
  private readonly openLandingVideoPopupBtn: JQuery<HTMLAnchorElement>;
  private readonly landingVideoPopupIframe: JQuery<HTMLIFrameElement>;
  private readonly landingVideoPopupCloseBtn: JQuery<HTMLElement>;
  private landingVideoPopup: NanoPopup;
  private carrousel: HorizontalCarrousel;

  constructor() {
    this.carrouselContainer = $("#main-container").find(".carrousel-container");
    this.openLandingVideoPopupBtn = $(".open-video-popup-btn");
    this.landingVideoPopupIframe = $(".landing-video-popup").find("iframe");
    this.landingVideoPopupCloseBtn = $(".landing-video-popup").find(
      ".close-container",
    );

    // testimonials carousel
    this.InitCarrousel(this.carrouselContainer);

    // video popup
    this.landingVideoPopup = new NanoPopup({
      target: ".nanopopup.landing-video-popup",
      onHide: () => {
        // reset video when popup is closed
        this.landingVideoPopupIframe.attr("src", "");
      },
    });
    // open video popup and load video src
    this.openLandingVideoPopupBtn.on("click", () => {
      this.landingVideoPopupIframe.attr(
        "src",
        this.landingVideoPopupIframe.attr("data-src"),
      );
      this.landingVideoPopup.Show();
    });
  }

  private InitCarrousel(container): void {
    this.carrousel = new HorizontalCarrousel({
      container: container,
      autoplay: true,
      shiftSlide: 1,
      speed: 15000,
    });
  }
}

$(window).on("load", () => {
  new ConstellationLanding();
});
